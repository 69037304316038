import React, { useState } from "react";
import Img from "gatsby-image";

import VideoModal from "./VideoModal";


const VideoButton = () => {
  const video = '';
  const videoTitle = '';
  const [showVideoModal, setShowVideoModal] = useState(false);

  const openVideoModal = () => {
    setShowVideoModal(prev => !prev);
  };

  return (
    <>
      <button
        className="absolute top-0 left-0 flex justify-center items-center w-full h-full bg-black bg-opacity-50 z-30 hero-image-width"
        onClick={ openVideoModal }
      >
        <h6 className="p-8 text-lg font-thin text-white border-white border-2">Play</h6>
      </button>
      <VideoModal
        video={ video }
        videoTitle={ videoTitle }
        showVideoModal={ showVideoModal }
        setShowVideoModal={ setShowVideoModal }
      />
    </>
  )
}


const SingleVideo = ({
  image,
  altText,
  video,
  videoTitle,
  videoAlignment,
  colorScheme
}) => {
  let style = {
    alignment: {},
    colorScheme: {},
  };

  if (videoAlignment === "left") {
    style.alignment = {
      videoContainer: "mb-16 lg:relative lg:float-left hero-image-width",
    };
  } else if (videoAlignment === "right") {
    style.alignment = {
      videoContainer: "mb-16 lg:relative lg:float-right hero-image-width",
    };
  } else if (videoAlignment === "center") {
    style.alignment = {
      videoContainer: "container mx-auto mb-16 lg:px-28",
    };
  }

  if (colorScheme === "dark") {
    style.colorScheme = {
      background: "w-full lg:absolute lg:top-0 lg:h-48 bg-blue-dark z-n20",
    }
  } else if (colorScheme === "light") {
    style.colorScheme = {
      background: "w-full lg:absolute lg:top-0 lg:h-48 bg-white z-n20",
    };
  }
  
  return (
    <section className="relative w-full lg:pt-16">
      <div className={ style.colorScheme.background }></div>
      <div className={ style.alignment.videoContainer }>
        <Img
          fluid={ image }
          alt={ altText }
        />
      </div>
    </section>
  )
}

export default SingleVideo;