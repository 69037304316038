import React from "react";

import { ButtonBare } from "../Button";


const TextBlock = ({
  heading,
  text,
  buttonText,
  pageLink
}) => {
  return (
    <section className="container mx-auto my-16 px-6 lg:px-28 xl:pl-80 xl:pr-96">
      { heading &&
        <h2 className="text-3xl font-thin">{ heading }</h2>
      }
      <div>
        <p className="mb-8 lg:text-lg font-thin">
          { text }
        </p>
        { buttonText &&
          <ButtonBare
            buttonText={ buttonText }
            buttonPosition="left"
            pageLink={ pageLink }
          />
        }
      </div>
    </section>
  )
}

export default TextBlock;