import React from "react";
import PropTypes from "prop-types";
import Img from "gatsby-image";

import { ButtonBare } from "../../Button";


const PartnerColumn = ({ 
  firstName,
  lastName,
  position, 
  phone, 
  email,
  image,
  altText,
  buttonText,
  buttonPosition,
  pageLink 
}) => {
  const phoneNumber = phone.length > 12 ? (phone.slice(0, 2) + " " + phone.slice(2, 5) + " " + phone.slice(5, 8) + " " + phone.slice(8, 12) + " ext. " + phone.slice(13, 14)) : (phone.slice(0, 2) + " " + phone.slice(2, 5) + " " + phone.slice(5, 8) + " " + phone.slice(8, 12));

  return (
    <div className="mb-8 lg:mb-0">
      <Img
        fluid={ image }
        alt={ altText }
      />
      <h3 className="pt-4 text-2xl font-thin">
        <span className="lg:block xl:inline">{ firstName } </span>
        <span className="lg:block xl:inline">{ lastName }</span>
      </h3>
      <div className="pt-4 pb-8 font-thin text-gray-600">
        <p className="text-black">{ position }</p>
        <p>
          <a href={ "tel:" + phone }>
            { phoneNumber }
          </a>
        </p>
        <p>
          <a href={ "mailto:" + email }>
            { email }
          </a>
        </p>
      </div>
      <ButtonBare
        buttonText={ buttonText }
        buttonPosition={ buttonPosition }
        pageLink={ pageLink }
      />
    </div>
  )
}

PartnerColumn.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  position: PropTypes.string,
  phone: PropTypes.string,
  email: PropTypes.string,
  buttonType: PropTypes.string,
  buttonText: PropTypes.string,
  buttonPosition: PropTypes.string,
  pageLink: PropTypes.string,
}

PartnerColumn.defaultProps = {
  firstName: '',
  lastName: '',
  title: '',
  position: '',
  phone: '',
  email: '',
  buttonType: '',
  buttonText: '',
  buttonPosition: '',
  pageLink: '', 
}

export default PartnerColumn;
