import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import PartnerColumn from "./PartnerColumn";


const PartnerGrid = ({
  heading,
  buttonText,
  buttonPosition
}) => {
  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: {
            frontmatter: {
              template: {
                eq: "partner"
              }
            }
          }
          sort: {
            fields: [frontmatter___order]
            order: ASC
          }
        ) {
          edges {
            node {
              frontmatter {
                firstName
                lastName
                position
                email
                phone
                description
                featuredImage {
                  childImageSharp {
                    fluid(maxWidth: 600, quality: 90) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
                altText
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `
  );

  const partnerColumns = allMarkdownRemark.edges.map(node => {
    return (
      <PartnerColumn 
        firstName={ node.node.frontmatter.firstName }
        lastName={ node.node.frontmatter.lastName }
        position={ node.node.frontmatter.position }
        phone={ node.node.frontmatter.phone }
        email={ node.node.frontmatter.email }
        image={ node.node.frontmatter.featuredImage.childImageSharp.fluid }
        altText={ node.node.frontmatter.altText }
        buttonText={ buttonText }
        buttonPosition={ buttonPosition }
        pageLink={ node.node.fields.slug }
      />
    )
  });
  
  return (
    <section className="container mx-auto my-16">
      <h2 className="mb-8 px-6 lg:px-28 text-3xl font-thin">{ heading }</h2>
      <div className="lg:grid lg:grid-cols-3 lg:gap-16 px-6 lg:px-28">
        { partnerColumns }
      </div>
    </section>
  )
}

export default PartnerGrid;