import React, { useCallback, useEffect } from "react";

import CloseButtonWhite from "../../assets/images/svg/icons/close/close-w.svg";


const VideoModal = ({
  video,
  videoTitle,
  showVideoModal,
  setShowVideoModal
}) => {
  const keyPress = useCallback(
    (e) => {
      if (e.key === "Escape" && showVideoModal) {
        setShowVideoModal(false);
      }
    },
    [setShowVideoModal, showVideoModal]
  );

  useEffect(
    () => {
      document.addEventListener("keydown", keyPress);
      return () => document.removeEventListener("keydown", keyPress);
    },
    [keyPress]
  );

  return (
    <>
      { showVideoModal ? (
        <div className="fixed top-0 left-0 flex justify-center items-center w-screen h-screen bg-black bg-opacity-90 z-50">
          <div className="w-3/4 h-3/4">
            <iframe
              src={ video }
              title={ videoTitle }
              className="w-full h-full"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              frameBorder="0"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen
            />
            <CloseButtonWhite
              className="absolute top-6 right-6 h-8"
              aria-label='Close modal'
              onClick={ () => setShowVideoModal(prev => !prev) }
            />
          </div>
        </div>
        ) : null
      }
    </>
  )
}

export default VideoModal;