import React from "react";
import { graphql, useStaticQuery } from "gatsby";

import CallToAction from "../../components/CallToAction/CallToAction";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import Hero from "../../components/Hero/Hero";
import Layout from "../../components/Layout/Layout";
import PartnerGrid from "../../components/Grid/Partners/PartnerGrid";
import SingleVideo from "../../components/SingleMedia/SingleVideo";
import TextBlock from "../../components/TextBlock/TextBlock";


const AboutPage = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/About.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 2400, quality: 60) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout
      title="About Us"
      description="KND Complex Litigation is a boutique Toronto law firm dedicated to protecting the 
      interests of investors and consumers. Our mission is to advocate for and foster fairness, 
      efficiency and integrity in consumer and capital markets in Canada."
    >
      <Header
        colorScheme="light"
      />

      <Hero
        title="We represent class members."
        subtitle="KND Complex Litigation is a boutique Toronto law firm that is solely dedicated to
        protecting the interests of investors and consumers. Our mission is to advocate for and
        foster fairness, efficiency and integrity in consumer and capital markets in Canada."
        colorScheme="light"
      />
      
      <SingleVideo
        image={ data.file.childImageSharp.fluid }
        altText="Strategic chess playing related to law"
        video="https://www.youtube.com/embed/L_LUpnjgPso?autoplay=1&mute=0"
        videoTitle="KND Complex Litigation"
        videoAlignment="left"
        colorScheme="light"
      />

      <TextBlock
        text="KND's lawyers have extensive experience with class actions and other complex litigation 
        across Canada in the areas of securities, privacy, consumer, competition, and employment law, 
        and have a track-record of success in obtaining favourable results for our clients."
      />

      <PartnerGrid 
        heading="Meet the team"
        headingAlignment="left"
        buttonText="Learn more"
        buttonPosition="left"
      />

      <CallToAction />

      <Footer />
    </Layout>
  );
}

export default AboutPage;