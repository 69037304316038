import React from "react";
import { Link } from "gatsby";

import BlackArrow from "../../assets/images/svg/icons/arrows/arrow-b.svg";
import WhiteArrow from "../../assets/images/svg/icons/arrows/arrow-w.svg";


const ButtonBare = ({
    buttonText,
    buttonPosition,
    contrast,
    pageLink
  }) => {
  return (
    <button>
      <Link to={ pageLink }>
        { buttonPosition === "left" &&
          <div className="flex flex-row items-center">
            <span className="flex-inital">
              { !contrast &&
                <BlackArrow className="w-6" />
              }
              { contrast &&
                <WhiteArrow className="w-6" />
              }
            </span>
            <span className="flex-inital pl-2 font-thin">{ buttonText }</span>
          </div>
        }
        { buttonPosition === "right" &&
          <div className="flex flex-row items-center">
            <span className="flex-inital pr-2 font-thin">{ buttonText }</span>
            <span className="flex-inital">
              { !contrast &&
                <BlackArrow className="w-6" />
              }
              { contrast &&
                <WhiteArrow className="w-6" />
              }
            </span>
          </div>
        }
      </Link>
    </button>
  )
}

export default ButtonBare;